import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterAuditLogs = ({ onChange, searches }: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={['BrandName', 'BrandId']}
      specialKeys={['BrandName']}
    />
  );
};

export default CustomFilterAuditLogs;
