import CustomFilter from '@Components/CustomFilter';
import { SortBy } from '@Types/Beacon';
import FilterTypes from '@Types/Filter';
type CustomFilterBeaconProps = {
  onChange: (searches: FilterTypes.Searches) => void;
  searches: FilterTypes.Searches;
};
const CustomFilterBeacon = ({
  onChange,
  searches,
}: CustomFilterBeaconProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={initialKeys}
      specialKeys={['Name']}
    />
  );
};

export default CustomFilterBeacon;
const initialKeys: SortBy[] = [
  'Id',
  'Name',
  'Surname',
  'ExternalId',
  'BtMacAddress',
  'Brand',
  'Store',
];
