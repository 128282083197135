import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import PageSkeletons from '@Components/PageSkeletons';
import BoardsDetail from '@Pages/Boards/Components/BoardsDetail';
import { getArchivedBoards, getUnarchivedBoards } from '@Store/Board/action';
import { getStoreDetail, resetCurrentStore } from '@Store/Store/action';

const StoreBoards = () => {
  const currentStore = useAppSelector(s => s.Store.currentStore.data);
  const { storeId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreDetail(storeId!));

    return () => {
      dispatch(resetCurrentStore());
    };
  }, []);

  if (!currentStore.Id) return <PageSkeletons />;
  return (
    <BoardsDetail
      getUnarchivedBoardsAction={getUnarchivedBoards}
      getArchivedBoardsAction={getArchivedBoards}
      title={currentStore.Brand + ' -> ' + currentStore.Name + ' - '}
    />
  );
};

export default StoreBoards;
