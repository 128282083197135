import usePaginate from '@Hooks/usePaginate';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Pagination, Spin } from 'antd';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import FilterTypes from '@Types/Filter';
import CustomFilter from './Components/CustomFilter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useMemo } from 'react';
import Status from '@Enums/Status';
import CardGridWrapper from '@Components/CardGridWrapper';
import { getAllHardware, getBoardBrandCameras } from '@Store/Hardware/action';
import { HardwareListForm } from '@Features/hardware/components';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { enumToIterable } from '@Utils/helpers';

const cameraTypes = enumToIterable(InterfaceCameraTypeEnums).map(
  (_, value) => value
);

function Hardware() {
  const { t } = useTranslation();
  const allHardware = useAppSelector(s => s.Hardware.allHardware);
  const boardBrandCameras = useAppSelector(s => s.Hardware.boardBrandCameras);

  const { pageConfig, onPageConfigChange } = useUserPageConfig('hardware', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
    pageSizeOnChange,
  } = usePaginate(pageConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBoardBrandCameras());
  }, []);

  useEffect(() => {
    getHardwares();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const getHardwares = () => {
    dispatch(
      getAllHardware({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };

  const hardwareMap = useMemo(() => {
    return allHardware.data?.Data?.map(hardware => {
      return cameraTypes.map(cameraType => {
        const boardBrandCamera = boardBrandCameras.data?.find(
          p =>
            p.BoardBrandModelId === hardware.Id &&
            p.CameraInterfaceType === cameraType
        );

        return {
          Id: boardBrandCamera?.Id ?? 0,
          CameraInterfaceType:
            boardBrandCamera?.CameraInterfaceType === undefined
              ? cameraType
              : boardBrandCamera?.CameraInterfaceType,
          BoardBrandModelId: hardware.Id,
          Name: hardware.Name,
          DefaultURL: boardBrandCamera?.DefaultURL ?? '',
        };
      });
    }).flat();
  }, [
    allHardware.data?.Data,
    allHardware.status,
    boardBrandCameras.status,
    boardBrandCameras.data,
  ]);

  return (
    <Card
      title={t('hardware')}
      className="Region"
      loading={allHardware.status === Status.pending}
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />

          <Link to="/hardware/new">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              className="create-hardware-button"
            >
              {t('addHardware')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allHardware.status}
            noData={allHardware.data?.Data?.length === 0}
            cards={
              !!(
                allHardware.status === Status.success &&
                boardBrandCameras.status === Status.success
              ) ? (
                <HardwareListForm
                  initialHardwares={hardwareMap}
                  getHardwares={getHardwares}
                />
              ) : (
                <Spin />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={allHardware.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allHardware.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

export default Hardware;
