import { useTranslation } from 'react-i18next';
import {
  getAllArchivedBoards,
  getAllUnarchivedBoards,
} from '@Store/Board/action';
import BoardsDetail from '@Pages/Boards/Components/BoardsDetail';

const AllBoards = () => {
  const { t } = useTranslation();

  return (
    <BoardsDetail
      getUnarchivedBoardsAction={getAllUnarchivedBoards}
      getArchivedBoardsAction={getAllArchivedBoards}
      title={t('allBoards') + ' - '}
    />
  );
};

export default AllBoards;
