import {
  AuthorizedPerson,
  Camera,
  CameraId,
  CameraStatuses,
  EditedCamera,
  FetchedCamerasResponse,
  GetCamerasParams,
  NewCamera,
} from '@Types/Camera';
import { DownloadCameraRecordParams } from '@Types/CameraRecords';
import { AllCameraRulesFetchResponse } from '@Types/CameraRules';
import {
  CameraCoordinate,
  CoordinatesCameraFilterParams,
} from '@Types/Coordinates';
import model from './index';

class CamerasService extends model {
  constructor() {
    super();
    this.Prefix('Cameras');
  }

  /**
   * @param {CameraId} cameraId
   * @description Archive camera
   * @returns Promise
   */
  async ArchiveCamera(cameraId: CameraId) {
    return this.PrefixId(cameraId).Post('Archive').offset().Save();
  }

  /**
   * @param {CameraId} cameraId
   * @description Unarchive camera
   * @returns Promise
   */
  async UnarchiveCamera(cameraId: CameraId) {
    return this.PrefixId(cameraId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {CameraId} cameraId
   * @param {EditedCamera} camera
   * @description Edit camera
   * @returns Promise
   */
  async EditCamera(cameraId: CameraId, camera: EditedCamera) {
    return this.PrefixId(cameraId).Put().Data(camera).Save();
  }

  /**
   * @param {CameraId} cameraId
   * @description Get camera detail
   * @returns Promise
   */
  async GetCameraDetail(cameraId: CameraId) {
    return this.PrefixId(cameraId).Get().offset().Save<Camera>();
  }

  /**
   * @param {PaginationParams} params
   * @description Get all available unarchived cameras
   * @returns Promise
   */
  async GetAllUnarchived(params: GetCamerasParams) {
    return this.Post('AllUnArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {GetCamerasParams} params
   * @description Get all available archived cameras
   * @returns Promise
   */
  async GetAllArchived(params: GetCamerasParams) {
    return this.Post('AllArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {NewCamera} camera
   * @description Add new camera
   * @returns Promise
   */
  async AddNewCamera(camera: NewCamera) {
    return this.Post('').Data(camera).Save();
  }

  /**
   * @param {CameraId} cameraId
   * @param {AuthorizedPerson} authorizedPerson
   * @description Set authorized person to a camera
   * @returns Promise
   */
  async SetAuthorizedPersonToCamera(
    cameraId: CameraId,
    authorizedPerson: AuthorizedPerson
  ) {
    return this.PrefixId(cameraId)
      .Post('SetAuthorizedPersonToCamera')
      .Data(authorizedPerson)
      .Save();
  }

  /**
   * @param {CameraId} cameraId
   * @description Get the camera thumbnail
   * @returns Promise
   */
  async GetCameraThumbnail(cameraId: CameraId) {
    return this.PrefixId(cameraId).Get('SmallPhoto').getImage();
  }

  /**
   * @param {DownloadCameraRecordParams} params
   * @description Download camera record video
   * @returns Promise
   */
  async DownloadCameraRecord(params: DownloadCameraRecordParams) {
    const res = await this.PrefixId(params.cameraId)
      .Post(
        `CameraRecords/${params.recordId}/Versions/${params.versionId}/DownloadCameraRecord`
      )
      .ResponseFile()
      .Save({ responseType: 'blob' } as any);
    this.DownloadFile(res);
    return Promise.resolve(true);
  }

  /**
   * @param {string}
   * @description Get Camera Coordinates
   * @returns Promise
   */

  async GetCameraCoordinates(param: CoordinatesCameraFilterParams) {
    const { cameraId, type, filterNumber, period } = param;
    return this.Get(
      `/${cameraId}/CameraCoordinates?type=${type}&filterNumber=${filterNumber}&period=${period}`
    )
      .offset()
      .Save<CameraCoordinate>();
  }

  /**
   * @param {CoordinatesCameraFilterParams} params
   * @description Get Camera Statuses
   * @returns Promise
   */

  async GetCamerasStatus(param: CoordinatesCameraFilterParams) {
    const { cameraId, type, filterNumber, period } = param;
    return this.Get(
      `/${cameraId}/CameraCoordinates?type=${type}&filterNumber=${filterNumber}&period=${period}`
    )
      .offset()
      .Save<CameraStatuses>();
  }

  /**
   * @param {CameraId} cameraId
   * @description Get camera detail
   * @returns Promise
   */
  async GetCameraRules(cameraId: CameraId) {
    return this.PrefixId(cameraId)
      .Get('Rules')
      .offset()
      .Save<AllCameraRulesFetchResponse>();
  }

  /**
   * @param {string} cameraIds
   * @description Get  unarchived cameras
   * @returns Promise
   */
  async GetUnarchived(cameraIds: string) {
    return this.Post(`/UnArchive/${cameraIds}`).Save();
  }
}

export default CamerasService;
