import useDataDisplay from '@Hooks/useDataDisplay';
import usePaginate from '@Hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import React, { useEffect, useState } from 'react';
import FilterTypes from '@Types/Filter';
import { convertDateTimeParam } from '@Utils/Api';
import { Card, Pagination, Row } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from './Components/CustomFilter';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import CardGridWrapper from '@Components/CardGridWrapper';
import SortByFilter from './Components/SortByFilter';
import DataDisplayTable from '@Components/DataDisplayTable';
import DateRangeSelector, { Dates } from '@Components/DateRangeSelector';
import { getAuditLogs } from '@Store/AuditLogs/action';
import AuditLogCard from '@Cards/AuditLogCard';
import moment from 'moment';
import { AllAuditLogs } from '@Types/AuditLogs';
import useAuditLogsColumns from './usePageLogsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';

export default function AuditLogs() {
  const [sdate, setSDate] = useState<string | null>('03/05/2021');
  const [edate, setEDate] = useState<string | null>(
    moment().format('DD/MM/YYYY')
  );
  const [stime, setSTime] = useState<string | null>('10:00');
  const [etime, setETime] = useState<string | null>('23:00');
  const [currentDisplay, toggleDisplay] = useDataDisplay('auditLogs');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auditLogs = useAppSelector(state => state.AuditLog.auditLogs);

  const getAllAuditLogs = () => {
    if (!sdate || !edate || !stime || !etime) return;
    dispatch(
      getAuditLogs({
        filters: {
          SortBy,
          OrderBy,
          PageIndex,
          Searches,
          PageSize,
        },
        dateTimeParams: convertDateTimeParam({ sdate, edate, stime, etime }),
      })
    );
  };

  const { pageConfig, onPageConfigChange } = useUserPageConfig('auditLogs', {
    orderBy: 'asc',
    sortKey: 'UserName',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: FilterTypes.Searches) => {
    onSearch(searches);
  };

  useEffect(() => {
    getAllAuditLogs();
  }, [
    PageIndex,
    OrderBy,
    SortBy,
    Searches,
    PageSize,
    sdate,
    edate,
    stime,
    etime,
  ]);

  const auditLogsTableColumns = useAuditLogsColumns({ auditLogs });

  const handleDateSelect = (dates: Dates) => {
    if (dates) {
      let [firstDate, secondDate] = dates;
      setSDate(firstDate ? firstDate.format('DD/MM/YYYY') : null);
      setEDate(secondDate ? secondDate.format('DD/MM/YYYY') : null);
      setSTime(firstDate ? firstDate.format('HH:mm') : null);
      setETime(secondDate ? secondDate.format('HH:mm') : null);
    }
  };

  return (
    <Card
      className="page-logs-detail-container"
      title={
        <Row className="card-grid-header">
          <SolutionOutlined />
          {t('auditLogs')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <DateRangeSelector onChange={handleDateSelect} />
        </div>

        <CardGridWrapper
          status={auditLogs.status}
          noData={auditLogs.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {auditLogs.data?.Data?.map((auditLog, i) => (
                  <AuditLogCard auditLog={auditLog} key={i} />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={auditLogsTableColumns}
                dataSource={auditLogs.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={auditLogs.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={auditLogs.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
