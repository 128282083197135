import { Option } from 'antd/lib/mentions';
import {
  TagOutlined,
  NumberOutlined,
  HomeOutlined,
  ShopOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterNotificationRules = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Type">
        {t('Type')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Id">
        {t('Id')}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="CameraId">
        {t('cameraId')}
        <CameraOutlined style={iconStyle} />
      </Option>
      <Option value="Camera">
        {t('Camera')}
        <CameraOutlined style={iconStyle} />
      </Option>
      <Option value="StoreId">
        {t('StoreId')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="Store">
        {t('Store')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="BrandId">
        {t('BrandId')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="Brand">
        {t('Brand')}
        <HomeOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterNotificationRules;
