import { Button, Input, Popover, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { FilterOutlined } from '@ant-design/icons';
import InnerCustomFilter from './InnerCustomFilter';
import { SearchKeyValue, Search } from '@Types/Filter';
import { CustomFilter as CustomFilterType } from '@Types/Components';
import { Option } from 'antd/lib/mentions';
import { ChangeEvent } from 'react';

interface Props<T> {
  filters: CustomFilterType<T>[];
  onChange: (newFilters: SearchKeyValue[]) => void;
  initialKeys: T[];
  specialKeys?: T[];
}

const CustomFilter = <T extends string>({
  filters,
  onChange,
  initialKeys,
  specialKeys,
}: Props<T>) => {
  let inputTimer: undefined | ReturnType<typeof setTimeout> = undefined;
  const { t } = useTranslation();

  const onFilterValueChange = ({ Key, Value }: { Key: T; Value: string }) => {
    const newFilters = [...filters];

    // Find the index of filter
    const index = newFilters.findIndex(f => f.Key === Key);

    if (index === -1) {
      // Add
      newFilters.push({ Key, Value });
    } else {
      // Delete filter if value is empty
      if (Value === '') {
        newFilters.splice(index, 1);
      } else {
        // Update
        newFilters[index] = { Key, Value };
      }
    }

    // Dispatch new filters
    onChange(newFilters);
  };

  const inputOnChange = (Key: T, e: ChangeEvent<HTMLInputElement>) => {
    if (inputTimer) {
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(
      () => onFilterValueChange({ Key, Value: e.target.value }),
      500
    );
  };

  return (
    <div className="custom-filter-container">
      {specialKeys?.map(key => (
        <div className="new-condition-container" key={key}>
          <Select value={key} disabled>
            <Option key={key} value={key}>
              {t(key)}
            </Option>
          </Select>
          <Input
            onChange={e => inputOnChange(key, e)}
            defaultValue={filters.find(f => f.Key === key)?.Value}
          />
        </div>
      ))}

      <Tooltip title={t('filterBy')}>
        <Popover
          content={
            <InnerCustomFilter
              initialKeys={initialKeys}
              onChange={onChange}
              filters={filters}
              specialKeys={specialKeys}
              onInputChange={inputOnChange}
            />
          }
          trigger="click"
          placement="bottom"
        >
          <Button icon={<FilterOutlined />} />
        </Popover>
      </Tooltip>
    </div>
  );
};

export default CustomFilter;
