import CustomFilter from '@Components/CustomFilter';
import { SortBy } from '@Types/User';
import FilterTypes from '@Types/Filter';
type CustomFilterUsersProps = {
  onChange: (searches: FilterTypes.Searches) => void;
  searches: FilterTypes.Searches;
};
const CustomFilterUsers = ({ onChange, searches }: CustomFilterUsersProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={initialKeys}
      specialKeys={['Name', 'Email']}
    />
  );
};

export default CustomFilterUsers;

const initialKeys: SortBy[] = [
  'BrandName',
  'CreatedDate',
  // 'Email',
  // 'Name',
  'RoleName',
  'StoreName',
  'Surname',
];
