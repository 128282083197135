import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterBrands = ({ onChange, searches }: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={[
        'Brand',
        'Store',
        'Camera',
        'WatchedByUser',
        'CreatedByUser',
        'Id',
        'ProcessId',
      ]}
    />
  );
};

export default CustomFilterBrands;
