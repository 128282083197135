import { createContext, useState } from 'react';
import { EntityId, IHomepageContext, IHomepageContextProvider } from './types';
import { GetBoardsParams } from '@Types/Board';
import { NO_PAGINATION_PAGE_SIZE } from '@Constants/Api';
import { useAppDispatch } from '@Store/hooks';
import { getArchivedBoards, getUnarchivedBoards } from '@Store/Board/action';
import { GetCamerasParams } from '@Types/Camera';
import {
  getAllArchivedCamerasOfStore,
  getAllUnarchivedCamerasOfStore,
} from '@Store/Camera/action';
import { PaginationParams } from '@Types/Filter';
import {
  getAllArchivedStores,
  getAllUnarchivedStores,
} from '@Store/Store/action';
import useQuery from '@Hooks/useQuery';
import {
  BRAND_QUERY_PARAM,
  GROUP_QUERY_PARAM,
  STORE_QUERY_PARAM,
} from '../homepage.constants';
import { getAllBrandsWithoutPagination } from '@Store/Brand/action';
import useBoolean from '@Hooks/useBoolean';

const INITIAL_STATE: IHomepageContext = {
  groupId: undefined,
  setGroupId: () => {},
  brandId: undefined,
  setBrandId: () => {},
  storeId: undefined,
  setStoreId: () => {},
  groupSearchValue: '',
  setGroupSearchValue: a => {},
  brandSearchValue: '',
  setBrandSearchValue: () => {},
  storeSearchValue: '',
  setStoreSearchValue: () => {},
  boardSearchValue: '',
  setBoardSearchValue: () => {},
  cameraSearchValue: '',
  setCameraSearchValue: () => {},

  getBrands: () => {},
  getStores: () => {},
  getBoards: () => {},
  getCameras: () => {},

  getQueryParam: () => '',
  setQueryParam: () => {},
  deleteQueryParam: () => {},

  showArchivedStores: {
    value: false,
    setFalse: () => {},
    setTrue: () => {},
    setValue: () => {},
    toggle: () => {},
  },
};

export const HomepageContext = createContext<IHomepageContext>(INITIAL_STATE);
export function HomepageContextProvider({
  children,
}: IHomepageContextProvider) {
  const {
    Params: getQueryParam,
    set: setQueryParam,
    delete: deleteQueryParam,
  } = useQuery();

  const [groupId, setGroupId] = useState<EntityId>(
    getParsedOrUndefined(GROUP_QUERY_PARAM)
  );
  const [brandId, setBrandId] = useState<EntityId>(
    getParsedOrUndefined(BRAND_QUERY_PARAM)
  );
  const [storeId, setStoreId] = useState<EntityId>(
    getParsedOrUndefined(STORE_QUERY_PARAM)
  );
  const [groupSearchValue, setGroupSearchValue] = useState<string>('');
  const [brandSearchValue, setBrandSearchValue] = useState<string>('');
  const [storeSearchValue, setStoreSearchValue] = useState<string>('');
  const [boardSearchValue, setBoardSearchValue] = useState<string>('');
  const [cameraSearchValue, setCameraSearchValue] = useState<string>('');

  const showArchivedStores = useBoolean();

  const dispatch = useAppDispatch();

  function getParsedOrUndefined(key: string) {
    const value: string | null = getQueryParam(key);

    if (value === null) {
      return undefined;
    }

    const parsedValue = parseInt(value);

    if (isNaN(parsedValue)) {
      return undefined;
    }

    return parsedValue;
  }

  function getBrands() {
    dispatch(getAllBrandsWithoutPagination());
  }

  function getStores(archived: boolean) {
    if (brandId) {
      const params: PaginationParams = {
        OrderBy: 'asc',
        PageIndex: 1,
        PageSize: NO_PAGINATION_PAGE_SIZE,
        SortBy: 'Name',
        Searches: [{ Key: 'BrandId', Value: brandId.toString() }],
      };
      if (archived) {
        dispatch(getAllArchivedStores(params));
      } else {
        dispatch(getAllUnarchivedStores(params));
      }
    }
  }

  function getBoards(archived: boolean) {
    if (storeId) {
      const params: GetBoardsParams = {
        filters: {
          OrderBy: 'asc',
          PageIndex: 1,
          PageSize: NO_PAGINATION_PAGE_SIZE,
          SortBy: 'Name',
          Searches: [],
        },
        storeId: storeId.toString(),
      };

      if (archived) {
        dispatch(getArchivedBoards(params));
      } else {
        dispatch(getUnarchivedBoards(params));
      }
    }
  }

  function getCameras(archived: boolean) {
    if (storeId) {
      const params: GetCamerasParams = {
        filters: {
          OrderBy: 'asc',
          PageIndex: 1,
          PageSize: NO_PAGINATION_PAGE_SIZE,
          SortBy: 'Name',
          Searches: [],
        },
        storeId,
      };

      if (archived) {
        dispatch(getAllArchivedCamerasOfStore(params));
      } else {
        dispatch(getAllUnarchivedCamerasOfStore(params));
      }
    }
  }

  function handleSetQueryParam(key: string, value: any) {
    if (value === undefined || value === null) {
      deleteQueryParam(key);
      return;
    }

    setQueryParam(key, value);
  }

  return (
    <HomepageContext.Provider
      value={{
        deleteQueryParam,
        getQueryParam,
        setQueryParam: handleSetQueryParam,
        getBrands,
        getStores,
        getBoards,
        getCameras,
        groupId,
        setGroupId,
        brandId,
        setBrandId,
        storeId,
        setStoreId,
        groupSearchValue,
        setGroupSearchValue,
        brandSearchValue,
        setBrandSearchValue,
        storeSearchValue,
        setStoreSearchValue,
        boardSearchValue,
        setBoardSearchValue,
        cameraSearchValue,
        setCameraSearchValue,
        showArchivedStores,
      }}
    >
      {children}
    </HomepageContext.Provider>
  );
}
