import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterPageLogs = ({ onChange, searches }: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={[
        'UserId',
        'Url',
        'BrandId',
        'Brand',
        'StoreId',
        'Store',
        'CreatedDate',
      ]}
      specialKeys={['UserName']}
    />
  );
};

export default CustomFilterPageLogs;
