import Card from '@Components/Card';
import { InfoCircleOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CamerasService from '@Services/Api/Cameras';
import { FetchedCamera } from '@Types/Camera';
import { apiCall, convertApiDate } from '@Utils/index';
import CameraService from '@Services/Api/Camera';
import { useEffect, useMemo, useState } from 'react';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { convertInterfaceCameraEnumToText } from '@Utils/Camera';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import CameraActionButtons from './Components/CameraActionButtons';
import './style.scss';
import StatusBadge from '@Components/StatusBadge';
import CardInfo, { CardInfoProps } from '@Components/Card/CardInfo';

type Props = {
  camera: FetchedCamera;
  isArchived: boolean;
  getCameras: () => void;
  hideHierarchyItems?: boolean;
};

export default function CameraCard(props: Props) {
  const { camera, hideHierarchyItems } = props;
  const [cameraThumbnail, setCameraThumbnail] = useState<string>('');

  useEffect(() => {
    getCameraThumbnail().then(thumbnail => {
      setCameraThumbnail(thumbnail || '');
    });
  }, []);

  const cameraActionPerm = useCheckPermission(Permissions.ADMIN_CAMERA_ACTION);

  const { t } = useTranslation();

  const getCameraThumbnail = async () => {
    try {
      return await new CamerasService().GetCameraThumbnail(camera.Id);
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  const getPhoto = async () => {
    const req = async () => await new CameraService().GetPhoto(camera.Id);

    try {
      const image = await apiCall(req, 'showImage', undefined, {
        hiddenStatuses: ['info', 'success'],
      });
      return image as string;
    } catch {
      return '';
    }
  };

  const infoLabels = useMemo(() => {
    const results: CardInfoProps[] = []
    if(camera.ArchivedBy){
        results.push({
          title:(
            <>
                <p>{`Arşivleyen:  ${camera.ArchivedBy}`}</p>
                <p>{t('date') + ': ' + convertApiDate(camera.ArchivedDate ?? '')}</p>
            </>),
          icon:<FolderOpenOutlined />})
    }
    return results;
  }, [t, camera.ArchivedBy, camera.ArchivedDate])

  return (
    <Card
      key={camera.Id + '-card'}
      className="camera-card"
      thumbnail={cameraThumbnail}
      largeThumbnailRequest={getPhoto}
      isDetailAlwaysOpen
      hideActionButtons={!cameraActionPerm}
      cardInfoItems={infoLabels}
      content={{
        firsItem: {
          custom: !hideHierarchyItems && (
            <div className="camera-card-inner">
              <HierarchyItems
                brandId={camera.BrandId}
                storeId={camera.StoreId}
                boardId={camera.BoardId}
                cameraId={camera.Id}
                brand={camera.Brand}
                store={camera.Store}
                board={camera.Board}
                camera={camera.Name}
                textWidth="15ch"
              />
            </div>
          ),
        },
      }}
      details={[
        {
          key: { text: t('status') },
          value: { text: <StatusBadge isOn={!camera.BoardPassive} /> },
        },
        {
          key: { text: 'Id' },
          value: { text: camera.Id },
        },
        {
          key: { text: t('name') },
          value: { text: camera.Name },
        },
        {
          key: { text: t('board') },
          value: {
            text: camera.Board || '-',
          },
        },
        {
          key: { text: t('lastDataTime') },
          value: {
            text: camera.LastDataTime
              ? convertApiDate(camera.LastDataTime, true)
              : '-',
          },
        },
        {
          key: {
            text: t('version'),
            props: {
              style: {
                wordBreak: 'normal',
              },
            },
          },
          value: {
            text: <Tooltip title={camera.Version}>{camera.Version}</Tooltip>,
          },
        },
        {
          key: { text: 'RTSP Url' },
          value: {
            text: camera.RTSPUrl ? (
              <Tooltip title={camera.RTSPUrl}>
                <InfoCircleOutlined />
              </Tooltip>
            ) : (
              '-'
            ),
          },
        },
        {
          key: { text: t('cameraType') },
          value: {
            text: convertInterfaceCameraEnumToText(camera.InterfaceType),
          },
        },
        {
          key: {
            text: (
              <Tooltip title={t('PhotoUploadedDate')}>{t('PhotoDate')}</Tooltip>
            ),
          },
          value: {
            text: camera.PhotoUploaded ? (
              <Typography.Text title={camera.PhotoUploaded}>
                {camera.PhotoUploaded}
              </Typography.Text>
            ) : (
              '-'
            ),
          },
        },
      ]}
      extraButtons={<CameraActionButtons {...props} />}
    />
  );
}
