import { Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  SortDescendingOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import useQuery from '@Hooks/useQuery';
import { useEffect } from 'react';

type props = {
  onChange: (value: string) => void;
  defaultValue?: string;
};
const OrderByFilter = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const { Params } = useQuery();

  useEffect(() => {
    handleChange(Params('orderBy') || defaultValue || 'desc');
  }, []);

  const handleChange = (value: string): void => {
    onChange(value);
  };

  return (
    <div className="order-filter-container">
      <Tooltip title={t('orderBy')}>
        <Select
          defaultValue={Params('orderBy') || defaultValue || 'desc'}
          onChange={handleChange}
        >
          <Select.Option value="asc">
            {t('ascending')} <SortAscendingOutlined />
          </Select.Option>
          <Select.Option value="desc">
            {t('descending')} <SortDescendingOutlined />
          </Select.Option>
        </Select>
      </Tooltip>
    </div>
  );
};

export default OrderByFilter;
