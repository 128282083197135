import { IpConfigurations } from '@Types/WifiConfigiration';
import { Button, Form, Input, Space, Tooltip } from 'antd';
import './style.scss';
import { StaticIpData } from '@Types/StaticIp';
import BoardsService from '@Services/Api/Boards';
import { stringToNumber } from '@Utils/Number';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getBoardWifiConfigiration } from '@Store/Board/action';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useMemo, useState } from 'react';
import {
  CloseOutlined,
  CopyOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { createMessage } from '@Utils/index';
import PageSkeletons from '@Components/PageSkeletons';
import { Popconfirm } from '@Components/Popconfirm';

type Props = {
  initialValue?: InitialValue;
  className?: string;
  interfaceLabel?: string;
  interfaceInput?: string;
  setAddButtonClick?: (status: boolean) => void;
  wifiConfigId?: number;
  name?: string;
};

interface InitialValue extends Partial<IpConfigurations> {
  DnsList: string[];
}

interface IForm extends StaticIpData {
  DnsList?: string[];
}

function StaticIpForm({
  initialValue,
  className,
  interfaceLabel,
  interfaceInput,
  setAddButtonClick,
  wifiConfigId,
  name,
}: Props) {
  const [staticIpStatus, setStaticIpStatus] = useState('');
  const [initialValues, setInitialValues] = useState<InitialValue>();

  const { boardId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const formValues = Form.useWatch([], form);

  useEffect(() => {
    if (initialValue) {
      const values = {
        BoardId: initialValue?.BoardId,
        DNSServer: initialValue?.DNSServer,
        Gateway: initialValue?.Gateway,
        IP: initialValue?.IP,
        SubnetMask: initialValue?.SubnetMask,
        NetworkInterface: initialValue?.NetworkInterface,
        DnsList: initialValue?.DnsList,
      };

      form.setFieldsValue(values);
      setInitialValues(values);
    } else {
      form.resetFields();
    }
  }, [form, initialValue]);

  const handleSubmit = async (staticIpData: IForm) => {
    let ipConfData = {
      ...staticIpData,
      DNSServer: convertDnsListToString(staticIpData.DnsList),
      NetworkInterface: interfaceInput || staticIpData?.NetworkInterface,
      SubnetMask: parseInt(staticIpData.SubnetMask),
    };

    delete ipConfData.DnsList;

    if (wifiConfigId) {
      ipConfData['WirelessConfId'] = wifiConfigId;
    }

    if (!hasValueInAnyField) {
      ipConfData = {} as any;
    }

    setStaticIpStatus('Pending');
    if (initialValue?.Id) {
      ipConfData.Id = initialValue?.Id;
    }

    try {
      await new BoardsService().PostBoardIPConfiguration(
        ipConfData,
        stringToNumber(boardId)
      );
      dispatch(getBoardWifiConfigiration(parseInt(boardId!)));
      setStaticIpStatus('Success');

      createMessage('success', t('successMessage'), ipConfData);
      if (setAddButtonClick) {
        setAddButtonClick(false);
      }
    } catch (e) {
      console.warn(e);
      setStaticIpStatus('Error');
    }
  };

  async function handleDelete() {
    if (initialValue?.Id) {
      setStaticIpStatus('Pending');
      await new BoardsService().DeleteIPConfiguration(
        parseInt(boardId!),
        initialValue?.Id
      );
      dispatch(getBoardWifiConfigiration(parseInt(boardId!)));
      setStaticIpStatus('Success');
      createMessage('success', t('successMessage'), initialValue?.Id);
    }
  }

  const handleCopy = (copyText: string | undefined) => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
      createMessage('success', t('copiedToClipboard'), copyText);
    }
  };

  const showSaveButton = useMemo(() => {
    if (!initialValue || !initialValues) {
      return true;
    }

    for (const key of Object.keys(formValues)) {
      const value1 = formValues[key];
      const value2 = initialValues[key as keyof IpConfigurations];
      const bothFalsy = !value1 && !value2;

      if (JSON.stringify(value1) !== JSON.stringify(value2) && !bothFalsy) {
        return true;
      }
    }

    return false;
  }, [initialValue, initialValues, formValues]);

  const hasValueInAnyField = useMemo(() => {
    if (!formValues) {
      return true;
    }

    for (const value of Object.values(formValues)) {
      const isArrayAndEmpty = Array.isArray(value) && value.length === 0;

      if (isArrayAndEmpty) {
        continue;
      }

      if (value) {
        return true;
      }
    }

    return false;
  }, [formValues]);

  if (staticIpStatus === 'Pending') return <PageSkeletons />;

  return (
    <Form
      onFinish={handleSubmit}
      className="staticIpForm"
      initialValues={initialValue}
      form={form}
      labelCol={{ span: 10 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
    >
      {name && <Form.Item label={t('name')}>{name}</Form.Item>}
      {interfaceLabel && (
        <Form.Item
          name="NetworkInterface"
          label={interfaceLabel}
          rules={[{ required: hasValueInAnyField }]}
        >
          <Input disabled={interfaceInput ? true : false} />
        </Form.Item>
      )}
      <Form.Item
        name="IP"
        label="IP Adresi"
        rules={[
          {
            required: hasValueInAnyField,
            message: 'Lütfen geçerli bir IP adresi girin.',
            pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
          },
        ]}
      >
        <Input
          placeholder="192.168.0.100"
          addonAfter={
            initialValue?.IP ? (
              <Tooltip title="Kopyala">
                <CopyOutlined
                  onClick={() => handleCopy(initialValue?.IP)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
      </Form.Item>
      <Form.Item
        name="SubnetMask"
        label="Alt Ağ Maskesi"
        rules={[
          {
            required: hasValueInAnyField,
            message: 'Lütfen geçerli bir alt ağ maskesi girin.',
            pattern: /^\d{1,2}$/,
          },
        ]}
      >
        <Input
          addonAfter={
            initialValue?.SubnetMask ? (
              <Tooltip title="Kopyala">
                <CopyOutlined
                  onClick={() =>
                    handleCopy(initialValue?.SubnetMask?.toString())
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
      </Form.Item>
      <Form.Item
        name="Gateway"
        label="Varsayılan Ağ Geçidi"
        rules={[
          {
            required: hasValueInAnyField,
            message: 'Lütfen geçerli bir varsayılan ağ geçidi girin.',
            pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
          },
        ]}
      >
        <Input
          placeholder="192.168.0.1"
          addonAfter={
            initialValue?.Gateway ? (
              <Tooltip title="Kopyala">
                <CopyOutlined
                  onClick={() => handleCopy(initialValue?.Gateway)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
      </Form.Item>

      <Form.Item
        required={hasValueInAnyField}
        label="DNS Sunucuları"
        rules={[{ required: hasValueInAnyField }]}
      >
        <Form.List
          name="DnsList"
          rules={[
            {
              validator: async (_, value) => {
                if (!hasValueInAnyField) {
                  return Promise.resolve();
                }

                if (Array.isArray(value) && value.length > 0) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'En az 1 adet DNS sunucusu girmelisiniz.'
                );
              },
            },
          ]}
        >
          {(fields, operations, { errors }) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}
            >
              {fields.map((field, i) => (
                <Space key={field.key} size={2}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={[i]}
                    label=""
                    rules={[
                      {
                        required: hasValueInAnyField,
                        message: 'Lütfen geçerli bir DNS sunucusu girin.',
                        pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="8.8.8.8"
                      addonAfter={
                        initialValue?.DNSServer ? (
                          <Tooltip title="Kopyala">
                            <CopyOutlined
                              onClick={() =>
                                handleCopy(initialValue?.DNSServer)
                              }
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type="text"
                    onClick={() => operations.remove(field.name)}
                    style={{ paddingInline: 8 }}
                  >
                    <CloseOutlined />
                  </Button>
                </Space>
              ))}
              <Form.ErrorList errors={errors} />
              <Button type="dashed" onClick={() => operations.add()} block>
                <PlusCircleOutlined />
              </Button>
            </div>
          )}
        </Form.List>
      </Form.Item>

      <div
        className={`${
          !initialValue
            ? 'modal-footer-container submitButtonItem'
            : 'modal-footer-container buttonItem'
        }`}
      >
        {initialValue?.Id && (
          <Popconfirm onConfirm={handleDelete}>
            <Button type="dashed" danger>
              {t('delete')}
            </Button>
          </Popconfirm>
        )}

        {showSaveButton && (
          <Button type="primary" htmlType="submit" className={className}>
            {t('save')}
          </Button>
        )}
      </div>
    </Form>
  );
}

export default StaticIpForm;

function convertDnsListToString(list?: string[]): string {
  if (!list || !Array.isArray(list)) {
    return '';
  }

  return list.join();
}
