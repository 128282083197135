import { Option } from 'antd/lib/mentions';
import { UserOutlined, TagOutlined, NumberOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterUsers = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Name">
        {t('name')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Id">
        Id
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="AccountManager">
        {t('accountManager')}
        <UserOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterUsers;
