import { Option } from 'antd/lib/mentions';
import { TagOutlined, NumberOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

export const SortByFilterBeaconRules = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Type">
        {t('Type')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Id">
        {t('Id')}
        <NumberOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};
