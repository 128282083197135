import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import { Permissions } from '@Enums/Permission';
import { CreateBrandGroupModal } from '@Features/brand-groups/modals';
import useBoolean from '@Hooks/useBoolean';
import useCheckPermission from '@Hooks/useCheckPermission';
import usePaginate from '@Hooks/usePaginate';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getBrandGroups } from '@Store/BrandGroup/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Searches, SortType } from '@Types/Filter';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Pagination } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBrandGroupsColumns from './useBrandGroupsColumns';
import {
  BrandGroupCard,
  CustomFilterBrandGroups,
  SortByFilterBrandGroups,
} from '@Features/brand-groups/components';
import OrderByFilter from '@Components/OrderByFilter';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import GoBackButton from '@Components/GoBackButton';
import { getAllBrandsWithoutPagination } from '@Store/Brand/action';

export function BrandGroupsPage() {
  const { t } = useTranslation();

  const [currentDisplay, toggleDisplay] = useDataDisplay('brandGroups');
  const brandGroups = useAppSelector(state => state.BrandGroup.brandGroups);

  const dispatch = useAppDispatch();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('brandGroups', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const {
    pageOnChange,
    pageSizeOnChange,
    onSearch,
    sortOnChange,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
  } = usePaginate(pageConfig);

  const getAllBrandGroups = () => {
    dispatch(
      getBrandGroups({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as SortType });
    onPageConfigChange({ orderBy: value as SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: Searches) => {
    onSearch(searches);
  };

  useEffect(() => {
    getAllBrandGroups();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  useEffect(() => {
    dispatch(getAllBrandsWithoutPagination());
  }, [dispatch]);

  const brandGroupsTableColumns = useBrandGroupsColumns({
    brandGroups,
    onRefetch: getAllBrandGroups,
  });

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const createBrandGroupModalState = useBoolean();

  return (
    <Card title={t('brand-groups')}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilterBrandGroups
            onChange={_sortOnChange}
            defaultValue={SortBy}
          />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilterBrandGroups onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />

          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={!boardActionPerm}
            onClick={createBrandGroupModalState.setTrue}
            style={{ marginLeft: 'auto' }}
          >
            {t('createGroup')}
          </Button>
          {createBrandGroupModalState.value && (
            <CreateBrandGroupModal
              modalState={createBrandGroupModalState}
              onRefetch={getAllBrandGroups}
            />
          )}
        </div>

        <CardGridWrapper
          status={brandGroups.status}
          noData={brandGroups.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {brandGroups.data?.Data?.map(brandGroup => (
                  <BrandGroupCard
                    key={brandGroup.Id}
                    brandGroup={brandGroup}
                    onRefetch={getAllBrandGroups}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={brandGroupsTableColumns}
                dataSource={brandGroups.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={brandGroups.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={brandGroups.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
