import { Option } from 'antd/lib/mentions';
import {
  UserOutlined,
  MailOutlined,
  ApartmentOutlined,
  ControlOutlined,
  WifiOutlined,
  ForkOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterVersion = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };
  //UploadDate;
  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Name">
        {t('versionName')}
        <ForkOutlined style={iconStyle} />
      </Option>
      <Option value="Version">
        {t('version')}
        <UserOutlined style={iconStyle} />
      </Option>
      <Option value="Main">
        {'Main'}
        <MailOutlined style={iconStyle} />
      </Option>
      <Option value="Model">
        {'Model'}
        <ApartmentOutlined style={iconStyle} />
      </Option>
      <Option value="Control">
        {t('control')}
        <ControlOutlined style={iconStyle} />
      </Option>
      <Option value="Beacon">
        {t('navigation.beacons')}
        <WifiOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterVersion;
