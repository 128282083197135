import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from '../CustomFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import ArchivedFilter from '../../../../Components/ArchivedFilter';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import { GetBoardsParams } from '@Types/Board';
import BoardCard from '@Cards/BoardCard';
import './style.scss';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayTable from '@Components/DataDisplayTable';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useBoardsColumns from './useBoardsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';

type Props = {
  getUnarchivedBoardsAction: Function;
  getArchivedBoardsAction: Function;
  title: string;
};

const BoardsDetail = ({
  getUnarchivedBoardsAction,
  getArchivedBoardsAction,
  title,
}: Props) => {
  const location = useLocation();
  const [showArchivedBoards, setShowArchivedBoards] = useState<boolean>(
    location.state?.showArchived
  );
  const fetchedBoards = useAppSelector(s => s.Board.fetchedBoards);
  const [currentDisplay, toggleDisplay] = useDataDisplay('boards');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { storeId } = useParams();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('boards', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  useEffect(() => {
    getBoards();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize, showArchivedBoards]);

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const toggleShowArchievedBoards = () => {
    setShowArchivedBoards(p => !p);
  };

  const getBoards = () => {
    const filters = { PageIndex, OrderBy, SortBy, PageSize, Searches };
    const params: GetBoardsParams = { filters, storeId };

    if (showArchivedBoards) {
      dispatch(getArchivedBoardsAction(params));
    } else {
      dispatch(getUnarchivedBoardsAction(params));
    }
  };

  const boardsTableColumns = useBoardsColumns({
    boards: fetchedBoards,
    getBoards,
    isArchived: showArchivedBoards,
  });

  return (
    <Card
      className="board-detail-container"
      title={
        <>
          {title}
          {showArchivedBoards ? t('archivedBoards') : t('unarchivedBoards')}
          <ArchivedFilter
            onClick={toggleShowArchievedBoards}
            showArchived={showArchivedBoards}
          />
        </>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          {storeId && (
            <Link to="new-board">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                disabled={!boardActionPerm}
              >
                {t('addBoard')}
              </Button>
            </Link>
          )}
        </div>

        <div className="card-grid-container">
          <CardGridWrapper
            status={fetchedBoards.status}
            noData={fetchedBoards.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {fetchedBoards.data?.Data?.map(b => (
                    <BoardCard
                      board={b}
                      key={b.Id}
                      isArchived={showArchivedBoards}
                      getBoards={getBoards}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={boardsTableColumns}
                  dataSource={fetchedBoards.data?.Data}
                />
              )
            }
          />
        </div>

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={fetchedBoards.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedBoards.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default BoardsDetail;
