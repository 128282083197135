import useDataDisplay from '@Hooks/useDataDisplay';
import usePaginate from '@Hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import React, { useEffect } from 'react';
import FilterTypes from '@Types/Filter';
import { Card, Divider, Pagination, Row, Typography } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from '../CustomFilter';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import CardGridWrapper from '@Components/CardGridWrapper';
import SortByFilter from '../SortByFilter';
import { getCameraRules } from '@Store/CameraRule/action';
import CameraRuleCard from '@Cards/CameraRuleCard';
import { Link } from 'react-router-dom';
import AddButton from '@Components/AddButton';
import useNotificationRulesColumns from '@Pages/NotificationRules/useNotificationRulesColumns';
import DataDisplayTable from '@Components/DataDisplayTable';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { addKeysToData } from '@Utils/index';
import { useMultipleRowSelector } from '@Hooks/useMultipleRowSelector';
import useNotificationRulesActions from '@Pages/NotificationRules/useNotificationRulesActions';
import { SelectAllButtonGroup } from '@Components/SelectAllButtonGroup';

export default function NotificationRulesDetails() {
  const [currentDisplay, toggleDisplay] = useDataDisplay('notificationRules');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cameraRules = useAppSelector(state => state.CameraRules.cameraRules);

  const getAllCameraRules = () => {
    dispatch(
      getCameraRules({
        filters: { SortBy, OrderBy, PageIndex, Searches, PageSize },
      })
    );
  };

  const { pageConfig, onPageConfigChange } = useUserPageConfig(
    'notificationRules',
    {
      orderBy: 'asc',
      sortKey: 'Id',
    }
  );

  const {
    hasSelectedRow,
    rowSelection,
    selectedRowKeys,
    selectAll,
    onSelect,
    isSelected,
    reset,
  } = useMultipleRowSelector(cameraRules.data?.Data || []);

  const { deleteCameraRules } = useNotificationRulesActions();

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: FilterTypes.Searches) => {
    onSearch(searches);
  };

  useEffect(() => {
    getAllCameraRules();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const notificationRulesTableColumns = useNotificationRulesColumns({
    cameraRules,
    getCameraRules: getAllCameraRules,
  });

  async function handleDelete() {
    await deleteCameraRules(selectedRowKeys as number[]);
    getAllCameraRules();
    reset();
  }

  return (
    <Card
      className="page-logs-detail-container"
      title={
        <Row className="card-grid-header">
          <NotificationOutlined />
          {t('notificationRules')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />

          <SelectAllButtonGroup
            onSelectAll={selectAll}
            onDelete={handleDelete}
            showActionButtons={hasSelectedRow}
          />

          <Link to="new">
            <AddButton />
          </Link>
        </div>

        <Divider />
        <Typography.Title level={5}>
          {t('notificationRulesDescription')}
        </Typography.Title>
        <Divider />

        <CardGridWrapper
          status={cameraRules.status}
          noData={cameraRules.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {cameraRules.data?.Data?.map(cameraRule => (
                  <CameraRuleCard
                    {...cameraRule}
                    isSelected={isSelected(cameraRule.Id)}
                    onSelect={() => onSelect(cameraRule.Id)}
                    getCameraRules={getAllCameraRules}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={notificationRulesTableColumns}
                dataSource={addKeysToData(cameraRules.data?.Data)}
                rowSelection={rowSelection}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={cameraRules.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={cameraRules.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
