import { Button, Card, Pagination, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import OrderByFilter from '@Components/OrderByFilter';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined, ForkOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
// import SortByFilter from './Components/SortByFilter';
// import CustomFilter from './Components/CustomFilter';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllGateway } from '@Store/Gateway/action';
import { GetGatewayParams } from '@Types/Gateway';
import SortByFilter from './Components/SortByFilter';
import CustomFilter from './Components/CustomFilter';
import GatewayCard from '@Cards/GatewayCard';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useGatewaysColumns from './useGatewaysColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';

const Gateways = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('gateways');
  const allGateway = useAppSelector(s => s.Gateaways.allGateway);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const { pageConfig, onPageConfigChange } = useUserPageConfig('gateways', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const filterParams = usePaginate(pageConfig);

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = filterParams;
  useEffect(() => {
    getGateways();
  }, [PageIndex, OrderBy, SortBy, PageSize, Searches]);

  const getGateways = () => {
    const params: GetGatewayParams = {
      filters: { PageIndex, OrderBy, SortBy, PageSize, Searches },
    };
    dispatch(getAllGateway(params));
  };

  function tableUpdate() {
    getGateways();
  }

  const gatewaysTableColumns = useGatewaysColumns({
    gateways: allGateway,
    getGateways,
  });

  return (
    <Card
      title={
        <Row className="card-grid-header">
          <ForkOutlined />
          Gateway
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-gateway">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addGateway')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allGateway.status}
            noData={allGateway.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {allGateway.data?.Data?.map(gateway => (
                    <GatewayCard gateway={gateway} getGateways={getGateways} />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={gatewaysTableColumns}
                  dataSource={allGateway.data?.Data}
                />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={allGateway.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allGateway.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default Gateways;
