import { CameraTypes, InterfaceCameraTypes } from '@Types/Camera';
import {
  ApiCallConfig,
  CheckboxPageUpdaterArgs,
  Notification,
  NotificationType,
} from '@Types/Dashboard';
import { message, notification } from 'antd';
import i18n from '../i18n';
import moment from 'moment';
import ErrorAlert from '@Components/ErrorAlert';
import NotificationTitle from '@Components/NotificationTitle';

export const createMessage = (
  type: Notification,
  content: React.ReactNode,
  key: any
) => {
  message[type]({
    content,
    key,
    duration: type === 'loading' ? 0 : type === 'error' ? 10 : 5,
    className: 'custom-ant-message',
  });
};

const openNotificationWithIcon = (
  type: NotificationType,
  description: React.ReactNode,
  key: any,
  duration?: number
) => {
  notification[type]({
    key,
    className: 'custom-ant-message',
    message: <NotificationTitle type={type} />,
    description,
    duration,
  });
};

/**
 *  @description                        This function is primarly used for alerting the user by using the status of an API call.
 *  @param {Promise<any>} func          Main function that will be executed.
 *  @param {string} translationKey      The key that will be used for translations.
 *  @param {Function} callBack          Optional callback function that will be called after getting success response.
 *  @param {ApiCallConfig} config       Another optional argument that is used to pass configuration parameters.
 */
export const apiCall = async (
  func: (...args: any[]) => Promise<any>,
  translationKey = 'default',
  callBack?: Function,
  config?: ApiCallConfig
) => {
  const id = Math.random() * 100000000;
  try {
    if (!config?.hiddenStatuses?.includes('info')) {
      openNotificationWithIcon(
        'info',
        i18n.t(`alert.loading.${translationKey}`),
        id,
        0
      );
    }
    const result = await func();
    if (!config?.hiddenStatuses?.includes('success')) {
      openNotificationWithIcon(
        'success',
        i18n.t(`alert.success.${translationKey}`),
        id,
        3
      );
    }
    if (callBack) callBack();
    return result;
  } catch (errorMessage: any) {
    if (!config?.hiddenStatuses?.includes('error')) {
      openNotificationWithIcon(
        'error',
        <ErrorAlert
          message={`alert.error.${translationKey}`}
          apiError={errorMessage.toString()}
        />,
        id,
        15
      );
    }
    if (config?.returnError) {
      return errorMessage;
    }
  }
};

/**
 * @param {string} stringDate ("2022-26-02T17:14:00Z" etc.)
 * @description Converts the date data came from the server to a more readible format.
 * @returns {string} "26/02/2022 20:14"
 */
export const convertApiDate = (
  stringDate: string,
  ignoreTimezone?: boolean
): string => {
  const date = moment(stringDate);
  if (!date.isValid()) return '-';

  if (ignoreTimezone)
    return date

      .add(new Date().getTimezoneOffset(), 'minutes')
      .format('DD/MM/YYYY HH:mm');
  return date.format('DD/MM/YYYY HH:mm');
};

export const cameraTypes: { type: CameraTypes; value: number }[] = [
  { type: 'Fisheye', value: 0 },
  { type: 'Flat', value: 2 },
  { type: 'Personnel Tracking', value: 3 },
];

/**
 * @param {CheckboxPageUpdaterArgs} args
 * @description Makes sure that the main page id is selected since we require permission to see that page if we want to see sub-pages of it.
 * @example https://skytuna.s-ul.eu/SbidPxwq
 * @returns {string[]} Updated checkboxes
 */
export const checkboxPageUpdater = (
  args: CheckboxPageUpdaterArgs
): string[] => {
  const { mainPage, subPages, values } = args;
  const newValues = [...values];

  const anySubPageChecked = subPages.some(i => newValues.includes(i));
  const isMainPageAlreadyChecked = newValues.includes(mainPage);

  // Make sure main page is added if any of sub-pages are selected
  if (anySubPageChecked && !isMainPageAlreadyChecked) {
    newValues.push(mainPage);
  }

  // Remove main page selection if none sub-page is selected
  if (!anySubPageChecked && isMainPageAlreadyChecked) {
    const index = newValues.findIndex(p => p === mainPage);
    newValues.splice(index, 1);
  }

  return newValues;
};

export const MINUTES_IN_HOUR = 60;

export const removeDuplicates = <T extends string>(duplicatedArray: T[]) => {
  return Array.from(new Set(duplicatedArray));
};

export function addKeysToData<
  T extends { Id: number | string; [key: string]: any }
>(data: T[]) {
  return data?.map(d => ({
    ...d,
    key: d.Id,
  }));
}

/**
 * @link https://stackoverflow.com/questions/12010552/jquery-javascript-mac-address-validation
 */
export function isValidMacAdress(address: string): boolean {
  const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
  return regex.test(address);
}

export const interFaceCameraTypes: {
  type: InterfaceCameraTypes;
  value: number;
}[] = [
  { type: 'CSI', value: 0 },
  { type: 'USB', value: 1 },
];

export function getImageDimensions(url: string) {
  const img = document.createElement('img');

  const promise = new Promise<{ width: number; height: number }>(
    (resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    }
  );

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;

  return promise;
}

export const fileToBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
