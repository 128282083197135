import CustomFilter from '@Components/CustomFilter';
import { SortBy } from '@Types/Gateway';
import FilterTypes from '@Types/Filter';
type CustomFilterGatewayProps = {
  onChange: (searches: FilterTypes.Searches) => void;
  searches: FilterTypes.Searches;
};
const CustomFilterGateway = ({
  onChange,
  searches,
}: CustomFilterGatewayProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={initialKeys}
      specialKeys={['ExternalId']}
    />
  );
};

export default CustomFilterGateway;
const initialKeys: SortBy[] = ['Id', 'Brand', 'Store', 'BrandId', 'StoreId'];
