import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_BRAND_GROUPS, GET_BRAND_GROUPS_WITHOUT_PAGINATON } from './type';
import { PaginationParams } from '@Types/Filter';
import BrandGroupService from '@Services/Api/BrandGroupService';
import { NO_PAGINATION_PAGE_SIZE } from '@Constants/Api';

export const getBrandGroups = createAsyncThunk(
  GET_BRAND_GROUPS,
  async (params: PaginationParams) => {
    try {
      const brandGroups = await new BrandGroupService().GetAllBrandGroups(
        params
      );
      return brandGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getBrandGroupsWithoutPagination = createAsyncThunk(
  GET_BRAND_GROUPS_WITHOUT_PAGINATON,
  async () => {
    try {
      const brandGroups = await new BrandGroupService().GetAllBrandGroups({
        OrderBy: 'desc',
        Searches: [],
        PageSize: NO_PAGINATION_PAGE_SIZE,
        PageIndex: 1,
        SortBy: 'Id',
      });
      return brandGroups;
    } catch (err) {
      console.error(err);
    }
  }
);
