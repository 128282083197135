import { useEffect, useState } from 'react';
import FilterType from '@Types/Filter';
import useQuery from './useQuery';

type DefaultParams = {
  orderBy?: FilterType.SortType;
  sortKey: string;
};

function usePaginate(defaults: DefaultParams): FilterType.usePaginateProps {
  const { set, Params } = useQuery();
  const defaultOrderBy = defaults?.orderBy || 'asc';
  const decodeFilters = () => {
    const paramfilters = Params('filters');
    if (!paramfilters) return [];
    let ExistParams: Array<FilterType.Search> = [];
    let filters = paramfilters.split(';');
    filters.map(filter => {
      const [Key, Value] = filter.split(':');
      ExistParams.push({ Key, Value });
    });
    return ExistParams;
  };

  const [page, setPage] = useState<number>(parseInt(Params('Page')!) || 1);
  const [pageSize, setPageSize] = useState<number>(
    parseInt(Params('pageSize')!) || 20
  );
  const [searches, setSearches] = useState<Array<FilterType.Search>>(
    decodeFilters()
  );
  const [searchesAsObject, setSearchesAsObject] = useState<{
    [key: string]: any;
  }>({});
  const [sortBy, setSortBy] = useState<FilterType.sortBy>({
    key: Params('sortBy') || defaults.sortKey,
    type:
      Params('orderBy') === 'desc' || Params('orderBy') === 'asc'
        ? (Params('orderBy') as FilterType.SortType)
        : defaultOrderBy,
  });

  const pageOnChange = (page: number) => {
    setPage(page);
    set('Page', page);
  };

  const pageSizeOnChange = (_pageSize: number) => {
    setPageSize(_pageSize);
    set('pageSize', _pageSize);
  };

  const onSearch = (searches: Array<FilterType.Search> = []) => {
    let filterArray: Array<string> = [];
    searches.map(search => {
      filterArray.push(`${search.Key}:${search.Value}`);
    });
    const filterString = filterArray.join(';');
    set('filters', filterString);
    setSearches(searches);
    setPage(1);
    set('Page', 1);
  };

  const sortOnChange: FilterType.SortOnChange = sort => {
    set('sortBy', sort.key);
    set('orderBy', sort.type);
    setSortBy(sort);
  };

  useEffect(() => {
    const searchObject = searches.reduce<{ [key: string]: any }>(
      (obj, search) => {
        obj[search.Key] = search.Value;
        return obj;
      },
      {}
    );
    setSearchesAsObject(searchObject);
  }, [searches]);

  return {
    PageIndex: page,
    PageSize: pageSize,
    Searches: searches,
    SortBy: sortBy.key, // column Data Key
    OrderBy: sortBy.type, // Asc Desc
    searchObject: searchesAsObject,
    pageOnChange: pageOnChange,
    pageSizeOnChange,
    onSearch,
    sortOnChange,
  };
}

export default usePaginate;
