import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import usePaginate from '@Hooks/usePaginate';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Searches, SortType } from '@Types/Filter';
import { Card, Pagination } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrderByFilter from '@Components/OrderByFilter';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import GoBackButton from '@Components/GoBackButton';
import { getTickets } from '@Store/Ticket/action';
import useTicketsTableColumns from './useTicketsTableColumns';
import {
  CustomFilterTickets,
  SortByFilterTickets,
  TicketCard,
} from '@Features/tickets/components';
import './style.scss';

export function TicketsPage() {
  const { t } = useTranslation();

  const [currentDisplay, toggleDisplay] = useDataDisplay('tickets');
  const tickets = useAppSelector(state => state.Ticket.tickets);

  const dispatch = useAppDispatch();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('tickets', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const {
    pageOnChange,
    pageSizeOnChange,
    onSearch,
    sortOnChange,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
  } = usePaginate(pageConfig);

  const getAllTickets = () => {
    dispatch(
      getTickets({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as SortType });
    onPageConfigChange({ orderBy: value as SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: Searches) => {
    onSearch(searches);
  };

  useEffect(() => {
    getAllTickets();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const ticketsTableColumns = useTicketsTableColumns({
    tickets,
    onRefetch: getAllTickets,
  });

  return (
    <Card title={t('tickets')} className="tickets-page">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilterTickets onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilterTickets onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
        </div>

        <CardGridWrapper
          status={tickets.status}
          noData={tickets.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {tickets.data?.Data?.map(ticket => (
                  <TicketCard
                    key={ticket.Id}
                    ticket={ticket}
                    onRefetch={getAllTickets}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={ticketsTableColumns}
                dataSource={tickets.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={tickets.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={tickets.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
