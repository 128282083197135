import usePaginate from '@Hooks/usePaginate';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import FilterTypes from '@Types/Filter';
import CustomFilter from './Components/CustomFilter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import Status from '@Enums/Status';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllLensType } from '@Store/LensType/action';
import LensCard from '@Cards/LensCard';

type Props = {};

function Lens({}: Props) {
  const { t } = useTranslation();
  const alllensType = useAppSelector(s => s.LensType.alllensType);

  const { pageConfig, onPageConfigChange } = useUserPageConfig('lens', {
    orderBy: 'asc',
    sortKey: 'Name',
  });
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
    pageSizeOnChange,
  } = usePaginate(pageConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getLens();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const getLens = () => {
    dispatch(
      getAllLensType({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  return (
    <Card
      title={t('lens')}
      className="Lens"
      loading={alllensType.status === Status.pending}
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />

          <Link to="/lens/new">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              className="create-lens-button"
            >
              {t('addLens')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={alllensType.status}
            noData={alllensType.data?.Data?.length === 0}
            cards={
              <React.Fragment>
                {alllensType.data?.Data?.map(lens => (
                  <LensCard
                    key={lens.Id}
                    lensType={lens}
                    getLensType={getLens}
                  />
                ))}
              </React.Fragment>
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={alllensType.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={alllensType.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

export default Lens;
