import { Button, Card, Pagination, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import OrderByFilter from '@Components/OrderByFilter';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined, ForkOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { GetVersionParams } from '@Types/Version';
import { getAllVersions } from '@Store/Version/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import VersionCard from '../../Cards/VersionCard';
import SortByFilter from './Components/SortByFilter';
import CustomFilter from './Components/CustomFilter';
import VersionFilesTable from './Components/VersionFilesTable';
import useVersionsColumns from './useVersionsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import DataDisplayTable from '@Components/DataDisplayTable';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';

const Versions = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('versions');
  const allVersions = useAppSelector(s => s.Version.allVersions);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const { pageConfig, onPageConfigChange } = useUserPageConfig('versions', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const filterParams = usePaginate(pageConfig);

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = filterParams;

  useEffect(() => {
    getVersions();
  }, [PageIndex, OrderBy, SortBy, PageSize, Searches]);

  const getVersions = () => {
    const params: GetVersionParams = {
      filters: { PageIndex, OrderBy, SortBy, PageSize, Searches },
    };
    dispatch(getAllVersions(params));
  };

  const versionsTableColumns = useVersionsColumns({
    versions: allVersions,
    getVersions,
  });

  return (
    <Card
      title={
        <Row className="card-grid-header">
          <ForkOutlined />
          {t('allVersions')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-version">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addVersion')}
            </Button>
          </Link>
        </div>
        <CardGridWrapper
          status={allVersions.status}
          noData={allVersions.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <>
                {allVersions.data?.Data?.map(vers => (
                  <VersionCard
                    key={vers.Id}
                    version={vers}
                    getVersions={getVersions}
                  />
                ))}
              </>
            ) : (
              <DataDisplayTable
                columns={versionsTableColumns}
                dataSource={allVersions.data?.Data}
              />
            )
          }
        />
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={allVersions.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allVersions.data?.TotalNumberOfRecords}
        />
      </div>

      <VersionFilesTable />
    </Card>
  );
};

export default Versions;
