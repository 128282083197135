import { createReducer } from '@reduxjs/toolkit';
import { onSidebarToggle } from './action';

interface initialStateInterface {
  isSidebarCollapsed: boolean;
}

const initialState: initialStateInterface = {
  isSidebarCollapsed: false,
};

const DashboardReducer = createReducer(initialState, builder => {
  builder.addCase(onSidebarToggle, (state, action) => {
    state.isSidebarCollapsed = action.payload;
  });
});

export default DashboardReducer;
