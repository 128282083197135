import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterBoards = ({ onChange, searches }: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={['CodeVersion', 'IP', 'WifiMac', 'ETHMac']}
      specialKeys={['Name', 'Id']}
    />
  );
};

export default CustomFilterBoards;
