import CameraCard from '@Cards/CameraCard';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useHomepageContext } from '@Features/homepage/hooks';
import { useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

interface IProps {
  showArchived: boolean;
}

export function CamerasList({ showArchived }: IProps) {
  const cameras = useAppSelector(s => s.Camera.fetchedCameras);

  const { storeId, cameraSearchValue, setCameraSearchValue, getCameras } =
    useHomepageContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (storeId) {
      getCameras(showArchived);
    }
  }, [storeId, showArchived]);

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setCameraSearchValue(e.target.value);
  }

  const filteredCameras = cameras?.data?.Data?.filter(
    camera =>
      camera.Id.toString().includes(cameraSearchValue) ||
      camera.Name.toLocaleLowerCase().includes(
        cameraSearchValue.toLocaleLowerCase()
      )
  );

  return (
    <div className="homepage-entity-selector-container">
      <Input
        onChange={searchChangeHandler}
        value={cameraSearchValue}
        placeholder={t('searchCameraNameAndIdPlaceholder')}
      />
      <CardGridWrapper
        cards={filteredCameras?.map(camera => (
          <CameraCard
            key={camera.Id}
            camera={camera}
            getCameras={() => getCameras(showArchived)}
            isArchived={showArchived}
            hideHierarchyItems
          />
        ))}
        noData={filteredCameras?.length === 0}
        status={cameras.status}
        error={cameras.ErrorMessage}
        containerClassName="homepage-entity-selector"
      />
    </div>
  );
}
