import useDataDisplay from '@Hooks/useDataDisplay';
import usePaginate from '@Hooks/usePaginate';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getBeaconRules } from '@Store/BeaconRule/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useTranslation } from 'react-i18next';
import FilterTypes from '@Types/Filter';
import React, { useEffect } from 'react';
import { Card, Divider, Pagination, Row, Typography } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import {
  BeaconRuleCard,
  CustomFilterBeaconRules,
  SortByFilterBeaconRules,
} from '@Features/beaconRules/components';
import OrderByFilter from '@Components/OrderByFilter';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import AddButton from '@Components/AddButton';
import { Link } from 'react-router-dom';
import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import { useBeaconRulesColumns } from './useBeaconRulesColumns';

export function BeaconRulesPage() {
  const [currentDisplay, toggleDisplay] = useDataDisplay('beaconRules');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const beaconRules = useAppSelector(state => state.BeaconRules.beaconRules);

  const { pageConfig, onPageConfigChange } = useUserPageConfig('beaconRules', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  const getAllBeaconRules = () => {
    dispatch(
      getBeaconRules({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: FilterTypes.Searches) => {
    onSearch(searches);
  };

  useEffect(() => {
    getAllBeaconRules();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const beaconRulesTableColumns = useBeaconRulesColumns({
    beaconRules,
    getBeaconRules: getAllBeaconRules,
  });

  return (
    <Card
      className="page-logs-detail-container"
      title={
        <Row className="card-grid-header">
          <NotificationOutlined />
          {t('beaconRules.beaconRules')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilterBeaconRules
            onChange={_sortOnChange}
            defaultValue={SortBy}
          />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilterBeaconRules onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="/beacon-rules/create">
            <AddButton />
          </Link>
        </div>

        <Divider />
        <Typography.Title level={5}>
          {t('beaconRules.description')}
        </Typography.Title>
        <Divider />

        <CardGridWrapper
          status={beaconRules.status}
          noData={beaconRules.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {beaconRules.data?.Data?.map(beaconRule => (
                  <BeaconRuleCard
                    beaconRule={beaconRule}
                    getBeaconRules={getAllBeaconRules}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={beaconRulesTableColumns}
                dataSource={beaconRules.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={beaconRules.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={beaconRules.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
