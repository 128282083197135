import { Card, Row } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SelectedFilters, { IDataForm } from '@Pages/Data/SelectedFilters';
import {
  BeaconDataLabels,
  BeaconDataSerial,
  BeaconDetailFilterParams,
  MultipleBeaconDetailFilterParams,
  StoreBeaconsDataSerial,
} from '@Types/Beacon';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  getBeaconData,
  resetStoreBeaconsData,
  getMultipleStoreBeaconsData,
} from '@Store/Beacons/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import BeaconDataCard from '@Cards/BeaconDataCard';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.scss';
import { convertStoreIdsToQueryParam } from '@Utils/Api';
type Props = {};

function BeaconData({}: Props) {
  const { t } = useTranslation();
  const { beaconId } = useParams();
  const dispatch = useAppDispatch();
  const { storesBeaconData, beaconDetail } = useAppSelector(s => s.Beacons);
  const [dataLabels, setDataLabels] = useState<BeaconDataLabels[]>([]);
  const [serialConvertedData, setserialConvertedData] = useState<
    BeaconDataSerial[]
  >([]);

  useEffect(() => {
    if (beaconId) {
      let getBeaconParam: BeaconDetailFilterParams = {
        beaconId: parseInt(beaconId),
        filterNumber: 48,
        period: 0,
      };
      dispatch(getBeaconData(getBeaconParam));
      if (beaconDetail.data.length > 0) {
        convertedData(beaconDetail.data);
      }
    }
  }, [beaconId]);

  useEffect(() => {
    if (storesBeaconData.data.length > 0 && !beaconId) {
      dispatch(resetStoreBeaconsData());
      convertedData(storesBeaconData.data);
    }
  }, [storesBeaconData.data]);
  useEffect(() => {
    if (beaconDetail.data.length > 0 && beaconId) {
      convertedData(beaconDetail.data);
    }
  }, [beaconDetail.data]);

  const onFinish = async (data: IDataForm) => {
    if (beaconId) {
      let beaconParams: BeaconDetailFilterParams = {
        beaconId: parseInt(beaconId),
        filterNumber: data.filterNumber,
        period: data.period,
      };
      dispatch(getBeaconData(beaconParams));
      if (beaconDetail.data.length > 0) {
        convertedData(beaconDetail.data);
      }
    } else {
      let param: MultipleBeaconDetailFilterParams = {
        brandId: data.brandId!,
        storeids: convertStoreIdsToQueryParam(data.storeIds!),
        filterNumber: data.filterNumber,
        period: data.period,
      };
      dispatch(getMultipleStoreBeaconsData(param));
      if (storesBeaconData.data.length > 0) {
        convertedData(storesBeaconData.data);
      }
    }
  };

  const convertedData = (data: StoreBeaconsDataSerial) => {
    let regexFindNumber = /\((.*?)\)/g;
    var convertedData = data[0].Serial.map((d, index) => ({
      Date: String(d).split('|')[0],
      BeaconName: String(d).split('|')[1],
      Value: data[1].Serial[index],
      Id: String(d)
        .split('|')[1]
        .match(regexFindNumber)?.[0]
        .replace(/[^\w\s]/gi, ''),
      storeId: data[2].Serial[index],
      storeName: data[3].Serial[index],
    }));
    setDataLabels(convertedData);
    const groupedData = convertedData.reduce<{ [key: string]: any }>(
      (group, product) => {
        group[product.BeaconName] = group[product.BeaconName] ?? [];
        group[product.BeaconName].push(product.Value);
        return group;
      },
      []
    );

    const serialConverted = Object.keys(groupedData).map((d, index) => ({
      Serial: groupedData[d],
      Name: d,
      storeId: parseInt(data[2].Serial[index].toString()),
      storeName: data[3].Serial[index].toString(),
    }));
    setserialConvertedData(serialConverted);
  };
  return (
    <Card
      className="BeaconData"
      title={
        <Row className="card-grid-header">
          {' '}
          <BarChartOutlined />
          {t('navigation.data')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row selectedFilters">
          <SelectedFilters onFinish={onFinish} />
        </div>
      </div>
      <div className="card-grid-container">
        <CardGridWrapper
          status={
            storesBeaconData
              ? storesBeaconData.status
              : beaconDetail && beaconDetail.status
          }
          noData={storesBeaconData.data?.length === 0}
          cards={
            <React.Fragment>
              {serialConvertedData.map(data => (
                <BeaconDataCard
                  data={data}
                  Label={dataLabels.filter(
                    label => label.BeaconName === data.Name
                  )}
                  Name={data.Name}
                  tooltipLabel={t('value')}
                  status={
                    storesBeaconData
                      ? storesBeaconData.status
                      : beaconDetail && beaconDetail.status
                  }
                />
              ))}
            </React.Fragment>
          }
        />{' '}
      </div>
    </Card>
  );
}

export default BeaconData;
