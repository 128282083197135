import { Button, Card, Pagination, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from './Components/CustomFilter';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { getAllUsers } from '@Store/User/action';
import UserCard from '@Cards/UserCard';
import { TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import DataDisplayTable from '@Components/DataDisplayTable';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useUsersColumns from './useUsersColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import './style.scss';

const AllBrands = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('users');
  const users = useAppSelector(state => state.User.allUsers);

  const { pageConfig, onPageConfigChange } = useUserPageConfig('users', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const filterParams = usePaginate(pageConfig);
  const {
    PageIndex,
    OrderBy,
    SortBy,
    Searches,
    pageOnChange,
    sortOnChange,
    onSearch,
    pageSizeOnChange,
    PageSize,
  } = filterParams;

  useEffect(() => {
    getUsers();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usersActionPerm = useCheckPermission(Permissions.ADMIN_USERS_ACTION);

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const getUsers = () => {
    dispatch(getAllUsers({ PageIndex, OrderBy, SortBy, PageSize, Searches }));
  };

  const usersTableColumns = useUsersColumns({ users, getUsers });

  return (
    <Card
      title={
        <Row className="card-grid-header">
          <TeamOutlined />
          {t('allUsers')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-user">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              disabled={!usersActionPerm}
            >
              {t('addUser')}
            </Button>
          </Link>
        </div>

        <div className="card-grid-container user-page-cards-container">
          <CardGridWrapper
            status={users.status}
            noData={users.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {users.data?.Data?.map(user => (
                    <UserCard key={user.Id} user={user} getUsers={getUsers} />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={usersTableColumns}
                  dataSource={users.data?.Data}
                />
              )
            }
          />
        </div>

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={users.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={users.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default AllBrands;
