import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from '../CustomFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { GetStoresParams } from '@Types/Store';
import ArchivedFilter from '@Components/ArchivedFilter';
import StoreCard from '@Cards/StoreCard';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import './style.scss';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import DataDisplayTable from '@Components/DataDisplayTable';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useStoresColumns from './useStoresColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';

type Props = {
  getUnarchivedStoresAction: any;
  getArchivedStoresAction: any;
  title: string;
};

const StoresDetail = ({
  getUnarchivedStoresAction,
  getArchivedStoresAction,
  title,
}: Props) => {
  const [showArchivedStores, setShowArchivedStores] = useState<boolean>(false);
  const [currentDisplay, toggleDisplay] = useDataDisplay('stores');
  const fetchedStores = useAppSelector(s => s.Store.fetchedStores);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { brandId } = useParams();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('stores', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  useEffect(() => {
    getStores();
  }, [PageIndex, OrderBy, SortBy, Searches, showArchivedStores, PageSize]);

  const storeActionPerm = useCheckPermission(Permissions.ADMIN_STORE_ACTION);

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const toggleShowArchievedStores = () => {
    setShowArchivedStores(p => !p);
  };

  const getStores = () => {
    const filters = { PageIndex, OrderBy, SortBy, Searches, PageSize };

    if (brandId) {
      const params: GetStoresParams = { filters, brandId };
      if (showArchivedStores) {
        dispatch(getArchivedStoresAction(params));
      } else {
        dispatch(getUnarchivedStoresAction(params));
      }
    } else {
      if (showArchivedStores) {
        dispatch(getArchivedStoresAction(filters));
      } else {
        dispatch(getUnarchivedStoresAction(filters));
      }
    }
  };

  const storesTableColumns = useStoresColumns({
    stores: fetchedStores,
    getStores,
    isArchived: showArchivedStores,
  });

  return (
    <Card
      className="store-detail-container"
      title={
        <>
          {title}
          {showArchivedStores ? t('archivedStores') : t('unarchivedStores')}
          <ArchivedFilter
            onClick={toggleShowArchievedStores}
            showArchived={showArchivedStores}
          />
        </>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          {brandId && (
            <Link to="new-store">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                disabled={!storeActionPerm}
              >
                {t('addStore')}
              </Button>
            </Link>
          )}
        </div>

        <CardGridWrapper
          status={fetchedStores.status}
          noData={fetchedStores.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {fetchedStores.data?.Data?.map(s => (
                  <StoreCard
                    store={s}
                    key={s.StoreId}
                    isArchived={showArchivedStores}
                    getStores={getStores}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={storesTableColumns}
                dataSource={fetchedStores.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={fetchedStores.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedStores.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default StoresDetail;
