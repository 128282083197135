import { Button, Card, Pagination } from 'antd';
import BrandCard from '@Cards/BrandCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from './Components/CustomFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllBrands } from '@Store/Brand/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import { PlusCircleOutlined } from '@ant-design/icons';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useBrandsColumns from './useBrandsColumns';
import ArchivedFilter from '@Components/ArchivedFilter';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import './style.scss';

const AllBrands = () => {
  const [showArchivedBrands, setShowArchivedBrands] = useState<boolean>(false);
  const [currentDisplay, toggleDisplay] = useDataDisplay('brands');
  const brands = useAppSelector(state => state.Brand.allBrands);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('brands', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
    pageSizeOnChange,
  } = usePaginate(pageConfig);

  useEffect(() => {
    dispatch(
      getAllBrands({
        PageIndex,
        OrderBy,
        SortBy,
        Searches,
        PageSize,
        isArchived: showArchivedBrands,
      })
    );
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize, showArchivedBrands]);

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const handlePageClick = (page: number) => {
    pageOnChange(page);
  };
  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const _onSearch = (searches: FilterTypes.Searches) => {
    onSearch(searches);
  };

  const toggleShowArchievedBrands = () => {
    setShowArchivedBrands(p => !p);
  };

  const brandsTableColumns = useBrandsColumns({ brands });

  return (
    <Card
      title={
        <div className="brands-title-container">
          {t('allBrands') + ' - '}
          {showArchivedBrands ? t('archivedBrands') : t('unarchivedBrands')}
          <ArchivedFilter
            onClick={toggleShowArchievedBrands}
            showArchived={showArchivedBrands}
          />
          <Link to="/brand-groups" className="groups-link">
            <Button type="primary">{t('groups')}</Button>
          </Link>
        </div>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={_onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-brand">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={!boardActionPerm}
            >
              {t('addBrand')}
            </Button>
          </Link>
        </div>

        <CardGridWrapper
          status={brands.status}
          noData={brands.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {brands.data?.Data?.map(brand => (
                  <BrandCard
                    brand={brand}
                    key={brand.Id}
                    isArchived={showArchivedBrands}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={brandsTableColumns}
                dataSource={brands.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={handlePageClick}
          pageSize={brands.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={brands.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default AllBrands;
