import { Option } from 'antd/lib/mentions';
import {
  TagOutlined,
  NumberOutlined,
  CalendarOutlined,
  LinkOutlined,
  IdcardOutlined,
  LaptopOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterAuditLogs = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="UserName">
        {t('UserName')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Type">
        {t('Type')}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="CreatedDate">
        {t('createdDate')}
        <CalendarOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};
export default SortByFilterAuditLogs;
