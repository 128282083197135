import Status from '@Enums/Status';
import { CreateReportButton, ReportsTable } from '@Features/reports/components';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllReports } from '@Store/Reports/action';
import { Card, Pagination } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from './Components/CustomFilter';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';

export default function ReportsPage() {
  const reports = useAppSelector(s => s.Reports.reports);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('reports', {
    orderBy: 'asc',
    sortKey: 'BrandName',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    PageSize,
    pageSizeOnChange,
  } = usePaginate(pageConfig);

  useEffect(() => {
    getAllReport();
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize]);

  const getAllReport = () => {
    dispatch(
      getAllReports({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  };

  useEffect(() => {}, []);

  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };

  return (
    <Card
      title={t('reports.reports')}
      loading={reports.status === Status.pending}
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <CreateReportButton />
        </div>
        <ReportsTable
          reports={reports.data}
          loading={reports.status === Status.pending}
        />
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={reports.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={reports.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
