import { Option } from 'antd/lib/mentions';
import {
  HomeOutlined,
  ApartmentOutlined,
  WifiOutlined,
  TagOutlined,
  NumberOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterVersion = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };
  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Id">
        {'Id'}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="Name">
        {t('Name')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Surname">
        {t('Surname')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="ExternalId">
        {t('externalId')}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="BtMacAddress">
        {'Bt Mac Address'}
        <ApartmentOutlined style={iconStyle} />
      </Option>
      <Option value="Brand">
        {t('Brand')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="Store">
        {t('Store')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="Battery">
        Battery
        <WifiOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterVersion;
