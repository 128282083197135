import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterNotificationRules = ({
  onChange,
  searches,
}: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      specialKeys={['CameraId', 'StoreId', 'BrandId']}
      initialKeys={['Id', 'Type', 'Camera', 'Store', 'Brand', 'Email']}
    />
  );
};

export default CustomFilterNotificationRules;
