import ModalFooter from '@Components/ModalFooter';
import ModalHeader from '@Pages/Users/Components/ModalHeader';
import {
  UnArchiveBrandCameraData,
  UnArchiveBrandCamerasForm,
} from '@Types/Brand';
import { InboxOutlined } from '@ant-design/icons';
import { Form, Checkbox, Card, Typography } from 'antd';
import { useState } from 'react';

const { Title } = Typography;

export type UnArchiveCameraFormProps = {
  onFinish: (values: UnArchiveBrandCamerasForm) => void;
  data?: UnArchiveBrandCameraData;
  titleKey: string;
};

function UnArchiveCameraForm({
  data,
  onFinish,
  titleKey,
}: UnArchiveCameraFormProps) {
  const [selectedCameras, setSelectedCameras] =
    useState<UnArchiveBrandCamerasForm>({ cameras: {} });

  const handleCheckboxChange = (storeId: string, cameraIds: string[]) => {
    setSelectedCameras(prevSelectedCameras => ({
      ...prevSelectedCameras,
      [storeId]: cameraIds,
    }));
  };

  return (
    <Card
      title={
        <ModalHeader titleKey={titleKey}>
          <InboxOutlined />
        </ModalHeader>
      }
    >
      <Form onFinish={onFinish} name="unarchive-camera-form">
        {Object.entries(data || {}).map(([storeId, cameras]) => (
          <div key={storeId}>
            <Title className="mb-5" level={5}>
              {cameras[0]?.Store}
            </Title>
            <Form.Item name={['cameras', storeId]}>
              <Checkbox.Group
                options={cameras.map(camera => ({
                  label: camera.Name,
                  value: camera.Id,
                }))}
                value={selectedCameras.cameras[parseInt(storeId)]}
                onChange={checkedValues =>
                  handleCheckboxChange(storeId, checkedValues as string[])
                }
              />
            </Form.Item>
          </div>
        ))}
        <ModalFooter formKey="unarchive-camera-form" />
      </Form>
    </Card>
  );
}

export default UnArchiveCameraForm;
