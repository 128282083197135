import { Option } from 'antd/lib/mentions';
import {
  HomeOutlined,
  WifiOutlined,
  NumberOutlined,
  ShopOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterVersion = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Id">
        {'Id'}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="ExternalId">
        {t('externalId')}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="Brand">
        {t('Brand')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="Store">
        {t('Store')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="Setup">
        {t('Setup')}
        <ImportOutlined style={iconStyle} />
      </Option>
      <Option value="LastOnlineDate">
        {t('LastOnlineDate')}
        <WifiOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterVersion;
