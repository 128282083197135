import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from '../CustomFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import ArchivedFilter from '../../../../Components/ArchivedFilter';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import { GetCamerasParams } from '@Types/Camera';
import CameraCard from '@Cards/CameraCard';
import './style.scss';
import PermissionCheck from '@HOC/PermissionCheck';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useCamerasColumns from './useCamerasColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getAllLensType } from '@Store/LensType/action';

type Props = {
  getUnarchivedCamerasAction: Function;
  getArchivedCamerasAction: Function;
  title: string;
};

const CamerasDetail = ({
  getUnarchivedCamerasAction,
  getArchivedCamerasAction,
  title,
}: Props) => {
  const location = useLocation();
  const [showArchivedCameras, setShowArchivedCameras] = useState<boolean>(
    location.state?.showArchived
  );
  const [currentDisplay, toggleDisplay] = useDataDisplay('cameras');
  const fetchedCameras = useAppSelector(s => s.Camera.fetchedCameras);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { boardId, storeId } = useParams();

  const { pageConfig, onPageConfigChange } = useUserPageConfig('cameras', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  useEffect(() => {
    getCameras();
    dispatch(
      getAllLensType({
        SortBy,
        OrderBy,
        PageIndex,
        Searches,
        PageSize,
      })
    );
  }, [PageIndex, OrderBy, SortBy, Searches, PageSize, showArchivedCameras]);

  const cameraActionPerm = useCheckPermission(Permissions.ADMIN_CAMERA_ACTION);

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const toggleShowArchievedCameras = () => {
    setShowArchivedCameras(p => !p);
  };

  const getCameras = () => {
    const filters = { PageIndex, OrderBy, SortBy, PageSize, Searches };
    const params: GetCamerasParams = {
      filters,
      boardId: parseInt(boardId!),
      storeId: parseInt(storeId!),
    };

    if (showArchivedCameras) {
      dispatch(getArchivedCamerasAction(params));
    } else {
      dispatch(getUnarchivedCamerasAction(params));
    }
  };

  const camerasTableColumns = useCamerasColumns({
    cameras: fetchedCameras,
    isArchived: showArchivedCameras,
    getCameras,
  });

  return (
    <Card
      className="camera-detail-container"
      title={
        <>
          {title}
          {showArchivedCameras ? t('archivedCameras') : t('unarchivedCameras')}
          <ArchivedFilter
            onClick={toggleShowArchievedCameras}
            showArchived={showArchivedCameras}
          />
        </>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          {boardId && (
            <PermissionCheck permission={Permissions.ADMIN_CAMERA_ADD}>
              <>
                <Link to="new-camera">
                  <Button
                    type="primary"
                    className="new-camera-btn"
                    icon={<PlusCircleOutlined />}
                    disabled={!cameraActionPerm}
                  >
                    {t('addCamera')}
                  </Button>
                </Link>
              </>
            </PermissionCheck>
          )}
        </div>

        <CardGridWrapper
          status={fetchedCameras.status}
          error={fetchedCameras.ErrorMessage}
          noData={fetchedCameras.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {fetchedCameras.data?.Data?.map(c => (
                  <CameraCard
                    camera={c}
                    key={c.Id}
                    isArchived={showArchivedCameras}
                    getCameras={getCameras}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={camerasTableColumns}
                dataSource={fetchedCameras.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={fetchedCameras.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedCameras.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default CamerasDetail;
