import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

const CustomFilterAuditLogs = ({ onChange, searches }: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={['Id', 'Name', 'BrandName', 'BrandId', 'Email']}
      specialKeys={['Name']}
    />
  );
};

export default CustomFilterAuditLogs;
