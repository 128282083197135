import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import OrderByFilter from '@Components/OrderByFilter';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined, ForkOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import SortByFilter from './Components/SortByFilter';
import CustomFilter from './Components/CustomFilter';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllReceiver } from '@Store/Receiver/action';
import ReceiverCard from '@Cards/ReceiverCard';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayTable from '@Components/DataDisplayTable';
import useReceiversColumns from './useReceiversColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import ArchivedFilter from '@Components/ArchivedFilter';

const Receiver = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('receivers');
  const allReceiver = useAppSelector(s => s.Receiver.allReceiver);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showArchivedReceiver, setShowArchivedReceiver] =
    useState<boolean>(false);

  const { pageConfig, onPageConfigChange } = useUserPageConfig('receivers', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };
  const filterParams = usePaginate(pageConfig);

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = filterParams;

  useEffect(() => {
    getReceiver();
  }, [PageIndex, OrderBy, SortBy, PageSize, Searches, showArchivedReceiver]);

  function getReceiver() {
    dispatch(
      getAllReceiver({
        PageIndex,
        OrderBy,
        SortBy,
        Searches,
        PageSize,
        archived: showArchivedReceiver,
      })
    );
  }

  const toggleShowArchievedReceiver = () => {
    setShowArchivedReceiver(p => !p);
  };

  const receiversTableColumns = useReceiversColumns({
    allReceiver,
    getAllReceivers: getReceiver,
  });

  return (
    <Card
      title={
        <>
          <ForkOutlined /> {t('navigation.receiver') + ' - '}
          {showArchivedReceiver
            ? t('archivedReceiver')
            : t('unarchivedReceiver')}
          <ArchivedFilter
            onClick={toggleShowArchievedReceiver}
            showArchived={showArchivedReceiver}
          />
        </>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-Receiver">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addReceiver')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allReceiver.status}
            noData={allReceiver.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {allReceiver.data?.Data?.map(receiver => (
                    <ReceiverCard
                      receiver={receiver}
                      isArchived={showArchivedReceiver}
                      getReceiver={getReceiver}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={receiversTableColumns}
                  dataSource={allReceiver.data?.Data}
                />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={allReceiver.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allReceiver.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default Receiver;
