import { getUnArchiveBrandCameras } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  BrandUnArchiveCamerasTypes,
  UnArchiveBrandCameraData,
  UnArchiveBrandCamerasForm,
} from '@Types/Brand';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UnArchiveCameraForm from '@Forms/UnArchiveCameraForm';
import CamerasService from '@Services/Api/Cameras';
import { useTranslation } from 'react-i18next';

type Props = {};

function UnArchive({}: Props) {
  const { brandId } = useParams();
  const dispatch = useAppDispatch();
  const unArchiveBrandCameras = useAppSelector(
    s => s.Brand.unArchiveBrandCameras.data
  );
  const [groupedData, setgroupedData] = useState<
    UnArchiveBrandCameraData | undefined
  >();
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    let param: BrandUnArchiveCamerasTypes = {
      brandId: parseInt(brandId!),
      archived: 1,
    };
    dispatch(getUnArchiveBrandCameras(param));
  }, []);

  useEffect(() => {
    const groupedData: UnArchiveBrandCameraData = {};

    unArchiveBrandCameras.forEach(item => {
      const storeId = item.StoreId;

      if (!groupedData[storeId]) {
        groupedData[storeId] = [];
      }

      groupedData[storeId].push(item);
    });
    setgroupedData(groupedData);
  }, [unArchiveBrandCameras]);

  const onFinish = async (values: UnArchiveBrandCamerasForm) => {
    const flattenedCameras = Object.values(values.cameras)
      .flatMap(cameraIds => cameraIds?.map(id => id))
      .join(',');

    try {
      await new CamerasService().GetUnarchived(flattenedCameras);
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <UnArchiveCameraForm
      onFinish={onFinish}
      data={groupedData}
      titleKey={t('unarchive')}
    />
  );
}

export default UnArchive;
