import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Link } from 'react-router-dom';
import CardGridWrapper from '@Components/CardGridWrapper';
import { getAllRoles } from '@Store/Role/action';
import RoleCard from '@Cards/RoleCard';
import { ApartmentOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './style.scss';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useRolesColumns from './useRolesColumns';

interface Props {}

function Roles({}: Props): ReactElement {
  const [currentDisplay, toggleDisplay] = useDataDisplay('roles');
  const dispatch = useAppDispatch();
  const allRoles = useAppSelector(state => state.Role.allRoles);
  const { t } = useTranslation();

  useEffect(() => {
    getRoles();
  }, [dispatch]);

  const rolesActionPerm = useCheckPermission(
    Permissions.ADMIN_CUSTOM_ROLE_ACTION
  );

  const getRoles = () => {
    dispatch(getAllRoles());
  };

  const rolesTableColumns = useRolesColumns({ roles: allRoles, getRoles });

  return (
    <Card
      title={
        <Row className="card-grid-header">
          <ApartmentOutlined />
          {t('allRoles')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-role">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={!rolesActionPerm}
            >
              {t('addRole')}
            </Button>
          </Link>
        </div>

        <CardGridWrapper
          status={allRoles.status}
          noData={allRoles.data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {allRoles.data?.map(role => (
                  <RoleCard key={role.RoleId} role={role} getRoles={getRoles} />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={rolesTableColumns}
                dataSource={allRoles.data}
              />
            )
          }
        />
      </div>
    </Card>
  );
}

export default Roles;
