import CustomFilter from '@Components/CustomFilter';
import { SortBy } from '@Types/Version';
import FilterTypes from '@Types/Filter';
type CustomFilterVersionProps = {
  onChange: (searches: FilterTypes.Searches) => void;
  searches: FilterTypes.Searches;
};
const CustomFilterVersion = ({
  onChange,
  searches,
}: CustomFilterVersionProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={initialKeys}
      specialKeys={['Name']}
    />
  );
};

export default CustomFilterVersion;
const initialKeys: SortBy[] = [
  'Name',
  'Version',
  'Main',
  'Model',
  'Control',
  'Beacon',
];
