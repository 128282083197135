import { Option } from 'antd/lib/mentions';
import {
  IdcardOutlined,
  TagOutlined,
  NumberOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterUsers = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Name">
        {t('name')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="Id">
        Id
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="CodeVersion">
        {t('CodeVersion')}
        <IdcardOutlined style={iconStyle} />
      </Option>
      <Option value="Status">
        {t('status')}
        <AreaChartOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterUsers;
