import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

export const CustomFilterTickets = ({
  onChange,
  searches,
}: CustomFilterProps) => {
  return (
    <CustomFilter onChange={onChange} filters={searches} initialKeys={['Id']} />
  );
};
