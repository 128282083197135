import { createAction } from '@reduxjs/toolkit';
import { ON_SIDEBAR_TOGGLE } from './type';

const onSidebarToggle = createAction(
  ON_SIDEBAR_TOGGLE,
  (isCollapsed: boolean) => {
    return {
      payload: isCollapsed,
    };
  }
);

export { onSidebarToggle };
