import {
  Button,
  Card,
  Divider,
  Empty,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import FilterTypes from '@Types/Filter';
import SortByFilter from './Components/SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from './Components/CustomFilter';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { EntityFilter } from './types';
import { getAllCamRecords } from '@Store/CameraRecords/action';
import { VideoCameraOutlined, PlusOutlined } from '@ant-design/icons';
import EntityIdFilter from './Components/EntityIdFilter';
import { CameraRecordTable, GetCamRecordsParams } from '@Types/CameraRecords';
import './style.scss';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import RecordProcessTable from './Components/RecordProcessTable';
import { convertCameraRecordEnum } from '@Utils/CameraRecords';
import { CameraRecordStatus } from '@Enums/CameraRecord';
import { LiteralUnion } from 'antd/lib/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { ColumnsType } from 'antd/lib/table';
import { getCodeVersions } from '@Store/CodeVersion/action';
import ProcessNewVersionButton from './Components/ProcessNewVersionButton';
import { convertApiDate } from '@Utils/index';
import RecordProcessStatusFilter from './Components/RecordProcessStatusFilter';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { RecordProcessProvider } from './Contexts';
import CameraRecordsSummary from './Components/CameraRecordsSummary';
import AssignTagModal from './Components/AssignTagModal';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { IsWatchedFilter } from './Components/IsWatchedFilter';

const CameraRecords = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [tableData, setTableData] = useState<CameraRecordTable[]>();
  const [entityIds, setEntityIds] = useState<EntityFilter>({
    brandId: undefined,
    storeId: undefined,
    cameraId: undefined,
    recordId: undefined,
    processStatus: undefined,
    iswatch: undefined,
  });
  const cameraRecords = useAppSelector(s => s.CameraRecord.allCamRecords);

  const { pageConfig, onPageConfigChange } = useUserPageConfig(
    'cameraRecords',
    {
      orderBy: 'desc',
      sortKey: 'Id',
    }
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    pageOnChange,
    sortOnChange,
    onSearch,
    SortBy,
    OrderBy,
    PageIndex,
    Searches,
    pageSizeOnChange,
    PageSize,
  } = usePaginate(pageConfig);

  useEffect(() => {
    dispatch(getCodeVersions());
  }, []);

  useEffect(() => {
    getCameraRecords();
  }, [PageIndex, OrderBy, SortBy, PageSize, Searches]);

  const camRecordActionPerm = useCheckPermission(
    Permissions.ADMIN_CAMERA_RECORDS_ACTION
  );

  useEffect(() => {
    if (cameraRecords.status === 'fulfilled') {
      const data = formatCameraRecordsForTable();
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [cameraRecords.status]);

  const formatCameraRecordsForTable = (): CameraRecordTable[] => {
    const formattedData: CameraRecordTable[] = [];

    if (!Array.isArray(cameraRecords.data?.Data)) {
      return [];
    }

    for (const cameraRecord of cameraRecords.data?.Data) {
      const startDate = convertApiDate(cameraRecord.StartDate);
      const endDate = convertApiDate(cameraRecord.EndDate);
      const formattedRecord: CameraRecordTable = {
        key: cameraRecord.Id,
        'record-Status': convertCameraRecordEnum(cameraRecord.Status),
        'record-StartDate': startDate,
        'record-EndDate': endDate,
        data: cameraRecord,
      };

      formattedData.push(formattedRecord);
    }

    return formattedData;
  };

  const getCameraRecords = () => {
    const params: GetCamRecordsParams = {
      filters: { PageIndex, OrderBy, SortBy, PageSize, Searches, Tags: tags },
      entityIds: mergeEntityIdsForAPI(),
    };

    dispatch(getAllCamRecords(params));
  };

  const handleGetCameraRecordsButtonClick = () => {
    // Reset page index
    pageOnChange(1);

    // Fetch camera records
    getCameraRecords();
  };

  const onOrderChange = (value: string) => {
    sortOnChange({ key: SortBy, type: value as FilterTypes.SortType });
    onPageConfigChange({ orderBy: value as FilterTypes.SortType });
  };
  const _sortOnChange = (value: string) => {
    sortOnChange({ key: value, type: OrderBy });
    onPageConfigChange({ sortKey: value });
  };

  const mergeEntityIdsForAPI = (): string => {
    let mergedIds = '';

    for (const [entity, value] of Object.entries(entityIds)) {
      if (!value) continue;
      mergedIds === '' ? (mergedIds += '?') : (mergedIds += '&');
      mergedIds += `${entity}=${value}`;
    }

    return mergedIds;
  };

  const findTagColorForStatus = (
    status: CameraRecordStatus
  ): LiteralUnion<PresetColorType | PresetStatusColorType, string> => {
    switch (status) {
      case 0:
        return 'geekblue';
      case 1:
        return 'blue';
      case 6:
        return 'lime';
      case 7:
        return 'processing';
      case 8:
        return 'error';
      case 10:
        return 'success';
    }

    return 'geekblue';
  };

  const handleTagChange = (values: string[]) => setTags(values || []);

  const columns: ColumnsType<CameraRecordTable> = [
    {
      title: t('recordId'),
      dataIndex: 'record-Id',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color="orange" icon={<VideoCameraOutlined />}>
          {record.data.Id}
        </Tag>
      ),
    },
    {
      title: t('entityIds'),
      dataIndex: 'record-EntityIds',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <HierarchyItems
          brandId={record.data.BrandId}
          brand={record.data.Brand}
          storeId={record.data.StoreId}
          store={record.data.Store}
          cameraId={record.data.CameraId}
          camera={record.data.Camera}
          textWidth="15ch"
        />
      ),
    },
    {
      title: t('status'),
      dataIndex: 'record-Status',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color={findTagColorForStatus(record.data.Status)}>
          {record['record-Status']}
        </Tag>
      ),
    },
    {
      title: t('recordRange'),
      dataIndex: 'record-Duration',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color="magenta">
          {
            <Space direction="vertical" size={1}>
              {record['record-StartDate']}
              {record['record-EndDate']}
            </Space>
          }
        </Tag>
      ),
    },
    {
      title: t('processWithNewVersion'),
      dataIndex: 'record-recordProcessWithNewVersion',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <ProcessNewVersionButton
          record={record}
          getCameraRecords={getCameraRecords}
        />
      ),
    },
    {
      title: t('tags'),
      dataIndex: 'record-recordProcessWithNewVersion',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <AssignTagModal cameraRecord={record.data} />
      ),
    },
  ];

  const recordProcessStatusFilterHandler = (status: number) => {
    setEntityIds(p => ({ ...p, processStatus: status }));
  };

  const isWatchedFilterHandler = (status: 'true' | 'false' | undefined) => {
    setEntityIds(p => ({ ...p, iswatch: status }));
  };

  return (
    <Card
      title={
        <Row className="card-grid-header">
          <VideoCameraOutlined />
          {t('cameraRecords')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter onChange={_sortOnChange} defaultValue={SortBy} />
          <OrderByFilter onChange={onOrderChange} defaultValue={OrderBy} />
          <CustomFilter onChange={onSearch} searches={Searches} />
          <Link to="new-camera-record">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={!camRecordActionPerm}
            >
              {t('addCameraRecord')}
            </Button>
          </Link>
        </div>

        <div className="filter-row">
          <EntityIdFilter entityIds={entityIds} setEntityIds={setEntityIds} />
          <RecordProcessStatusFilter
            onChange={recordProcessStatusFilterHandler}
            value={entityIds.processStatus}
          />
          <IsWatchedFilter
            onChange={isWatchedFilterHandler}
            value={entityIds.iswatch}
          />
          <Select
            mode="tags"
            placeholder={t('camRecTagPlaceholder')}
            onChange={handleTagChange}
            className="tag-filter"
          />
          <Button
            className="get-cam-records-btn"
            type="primary"
            onClick={handleGetCameraRecordsButtonClick}
          >
            {t('getCameraRecords')}
          </Button>
        </div>

        {cameraRecords.data.TotalStatuses && (
          <CameraRecordsSummary
            className="camera-records-summary"
            totalStatuses={cameraRecords.data.TotalStatuses}
          />
        )}

        <CardGridWrapper
          status={cameraRecords.status}
          noData={cameraRecords.data?.Data?.length === 0}
          cards={
            <Table
              columns={columns}
              dataSource={tableData}
              expandable={{
                expandedRowKeys: tableData?.map(o => o.key),
                expandedRowRender: r => (
                  <>
                    <RecordProcessProvider>
                      <RecordProcessTable
                        record={r}
                        getCameraRecords={getCameraRecords}
                      />
                    </RecordProcessProvider>
                    <Divider />
                  </>
                ),
                showExpandColumn: false,
              }}
              bordered
              size="small"
              pagination={false}
              locale={{
                emptyText: <Empty description={t('noData')} />,
              }}
            />
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={PageIndex}
          onChange={pageOnChange}
          pageSize={cameraRecords.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pageSizeOnChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={cameraRecords.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default CameraRecords;
